<template>
  <section id="how-it-works">
    <h2 class="how-it-works-content-h2">How It Works</h2>

    <!-- First Step: Create an Account with Navigation -->
    <router-link to="/sign-up" class="step-link">
      <div class="step">
        <i class="bi bi-person-plus-fill step-icon"></i>
        <!-- Changed icon to user account creation -->
        <h3>Create an Account</h3>
        <p>Sign up for a GIVIO account to start accessing the platform.</p>
      </div>
    </router-link>

    <!-- Second Step: Choose Your Gift Card with Navigation -->
    <router-link to="/gift-cards" class="step-link">
      <div class="step">
        <i class="bi bi-gift-fill step-icon"></i>
        <h3>Choose Your Gift Card</h3>
        <p>Browse and select gift cards from various brands.</p>
      </div>
    </router-link>

    <div class="step">
      <!-- Replace Bitcoin icon with Cardano logo -->
      <img
        src="@/assets/cardano-ada-logo.png"
        class="step-icon cardano-icon"
        alt="Cardano Logo"
      />
      <h3>Pay with Cardano</h3>
      <p>Use Cardano tokens to pay for your gift card.</p>
    </div>

    <div class="step">
      <i class="bi bi-envelope-check-fill step-icon"></i>
      <h3>Receive Your Code</h3>
      <p>Get your gift card code via email after transaction confirmation.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "GuideComponent",
};
</script>

<style scoped>
/* Main section styles */
#how-it-works {
  background: linear-gradient(135deg, #f9f9f9, #eaeaea);
  color: black;
  text-align: center;
  padding: 80px 20px;
}

/* Content container */
.how-it-works-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Section title */
.how-it-works-content-h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.4rem;
  margin-bottom: 10px;
  color: #333;
}

/* Individual step */
.step {
  margin-bottom: 3rem;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

/* Icon styles */
.step-icon {
  font-size: 70px;
  color: #007bff;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.step:hover .step-icon {
  color: #0056b3;
}

/* Cardano logo specific styles */
.cardano-icon {
  max-width: 70px;
  height: auto;
}

.step:hover .cardano-icon {
  filter: brightness(0.8); /* Slight darkening effect on hover */
}

/* Step heading */
.step h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

/* Step description */
.step p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.5;
}

/* Add a hover effect and clickable behavior for the first step */
.step-link {
  text-decoration: none;
  color: inherit; /* Keeps the original color */
}

.step-link:hover .step {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.step-link:hover .step-icon {
  color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
  .how-it-works-content {
    padding: 20px;
  }

  .step {
    margin-bottom: 2rem;
  }

  .step-icon {
    font-size: 50px;
  }

  .cardano-icon {
    max-width: 50px;
  }

  .step h3 {
    font-size: 1.6rem;
  }

  .step p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .step-icon {
    font-size: 40px;
  }

  .cardano-icon {
    max-width: 40px;
  }

  .step h3 {
    font-size: 1.4rem;
  }

  .step p {
    font-size: 0.9rem;
  }
}
</style>
