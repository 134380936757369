<template>
  <!-- Entire page wrapped in a card -->
  <div class="container mt-4">
    <div class="card shadow-lg p-5 card-wrapper">
      <h1 class="text-center mb-5">Privacy Policy</h1>

      <!-- Privacy Policy Section -->
      <div class="card mb-4 shadow-sm p-4 card-content">
        <h2>1. Data Collection</h2>
        <p>
          We value your privacy and are committed to protecting your personal
          data. We collect the following information from users:
        </p>
        <ul>
          <li>
            <strong>Personal Information</strong>: Name, email address, and any
            other data provided during registration or transactions.
          </li>
          <li>
            <strong>Transaction Information</strong>: Details of your
            transactions.
          </li>
          <li>
            <strong>Technical Data</strong>: IP address, browser type, device
            information.
          </li>
        </ul>

        <h3>How We Use Your Data</h3>
        <p>
          Your data is used to improve our services, communicate with you, and
          fulfill legal obligations.
        </p>
      </div>

      <div class="card mb-4 shadow-sm p-4 card-content">
        <h2>2. User Rights</h2>
        <p>
          As a user, you have the following rights regarding your personal data:
        </p>
        <ul>
          <li>
            <strong>Right to Access</strong>: Request a copy of your personal
            data.
          </li>
          <li>
            <strong>Right to Rectification</strong>: Correct inaccurate data.
          </li>
          <li>
            <strong>Right to Erasure</strong>: Request deletion of your data.
          </li>
          <li>
            <strong>Right to Restrict Processing</strong>: Limit the processing
            of your data in certain cases.
          </li>
          <li>
            <strong>Right to Object</strong>: Object to certain data uses, like
            direct marketing.
          </li>
        </ul>
        <p>
          To exercise your rights, contact us at
          <strong>[your contact information]</strong>.
        </p>
      </div>

      <div class="card mb-4 shadow-sm p-4 card-content">
        <h2>3. Security Measures</h2>
        <p>
          We implement various security measures to ensure your data is
          protected:
        </p>
        <ul>
          <li>
            <strong>Data Encryption</strong>: Sensitive information is
            encrypted.
          </li>
          <li>
            <strong>Access Control</strong>: Only authorized personnel have
            access to personal data.
          </li>
          <li>
            <strong>Regular Audits</strong>: We conduct regular security audits
            to ensure data protection.
          </li>
        </ul>
        <p>
          While we strive to protect your data, no transmission over the
          internet is entirely secure.
        </p>
      </div>

      <!-- Terms of Service Section -->
      <h1 class="text-center mb-5">Terms of Service</h1>

      <div class="card mb-4 shadow-sm p-4 card-content">
        <h2>1. Usage Terms</h2>
        <p>By using our platform, you agree to the following terms:</p>
        <ul>
          <li>
            <strong>Eligibility</strong>: You must be 18 years of age to use our
            services.
          </li>
          <li>
            <strong>Account Responsibility</strong>: You are responsible for the
            confidentiality of your account.
          </li>
          <li>
            <strong>Prohibited Activities</strong>: You agree not to engage in
            illegal or harmful activities.
          </li>
        </ul>
      </div>

      <div class="card mb-4 shadow-sm p-4 card-content">
        <h2>2. Legal Disclaimers</h2>
        <p>We make no warranties regarding:</p>
        <ul>
          <li>
            <strong>Service Availability</strong>: We are not liable for
            disruptions in service.
          </li>
          <li>
            <strong>Third-Party Content</strong>: We are not responsible for
            external websites or services linked from our platform.
          </li>
        </ul>
      </div>

      <div class="card mb-4 shadow-sm p-4 card-content">
        <h2>3. Limitations of Liability</h2>
        <p>
          We are not liable for indirect, incidental, or consequential damages
          resulting from the use of our platform, unauthorized access, or other
          related incidents.
        </p>
      </div>

      <p>
        If you have any questions, contact us at
        <strong>info@givio.io</strong>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyPage",
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 2.8rem;
  color: #333;
  font-weight: bold;
}

h2,
h3 {
  color: #0056b3;
  font-weight: 600;
}

p {
  font-size: 1.1rem;
  color: #555;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

.card {
  background-color: #f8f9fa;
  border-radius: 15px;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-wrapper {
  background: linear-gradient(135deg, #ffffff, #f0f2f5);
  border-radius: 20px;
  padding: 40px;
}

.card-content {
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  transition: all 0.3s ease;
}

.card-content:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

ul li {
  padding-left: 1.2rem;
}

.shadow-sm {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

p,
ul {
  line-height: 1.6;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.2rem;
  }
}
</style>
