import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import AboutUsPage from "@/views/AboutUsPage.vue";
import PassSalePage from "@/views/PassSalePage.vue";
import GiftCardsPage from "@/views/GiftCardsPage.vue";
import CustomerSupportPage from "@/views/CustomerSupportPage.vue";
import FrequentlyAskedQuestionsPage from "@/views/FrequentlyAskedQuestionsPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import SignUpPage from "@/views/SignUpPage.vue";
import PrivacyPolicyPage from "@/views/PrivacyPolicyPage.vue";
import ProfilePage from "@/views/ProfilePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/about",
    name: "About",
    component: AboutUsPage,
  },
  {
    path: "/pass-sale", // Set the URL path you want for the pass sale page
    name: "PassSale",
    component: PassSalePage,
  },
  {
    path: "/gift-cards",
    name: "GiftCards",
    component: GiftCardsPage,
  },
  {
    path: "/customer-support",
    name: CustomerSupportPage,
    component: CustomerSupportPage,
  },
  {
    path: "/frequently-asked-questions",
    name: FrequentlyAskedQuestionsPage,
    component: FrequentlyAskedQuestionsPage,
  },
  {
    path: "/login",
    name: LoginPage,
    component: LoginPage,
  },
  {
    path: "/sign-up",
    name: SignUpPage,
    component: SignUpPage,
  },
  {
    path: "/privacy-policy",
    name: PrivacyPolicyPage,
    component: PrivacyPolicyPage,
  },
  {
    path: "/profile",
    name: ProfilePage,
    component: ProfilePage,
  },
  // Add other routes here
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

export default router;
