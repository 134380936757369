<template>
  <section id="faq" class="container mt-5">
    <h1 class="text-center mb-4">Frequently Asked Questions (FAQ)</h1>

    <!-- FAQ Section -->
    <div class="accordion" id="faqAccordion">
      <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
        <h2 class="accordion-header" :id="'heading' + index">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + index"
            :aria-expanded="index === 0 ? 'true' : 'false'"
          >
            {{ faq.question }}
          </button>
        </h2>
        <div
          :id="'collapse' + index"
          class="accordion-collapse collapse"
          :class="{ show: index === 0 }"
          :aria-labelledby="'heading' + index"
          data-bs-parent="#faqAccordion"
        >
          <div class="accordion-body">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: "How do I purchase a gift card?",
          answer:
            "Simply browse our gift card selection, select a card, and proceed to checkout with your preferred payment method.",
        },
        {
          question: "What is the delivery time for gift cards?",
          answer:
            "All gift cards are delivered instantly via email after payment confirmation.",
        },
        {
          question: "Can I refund or exchange a gift card?",
          answer:
            "Unfortunately, gift cards cannot be refunded or exchanged once purchased.",
        },
        {
          question: "How do I contact customer support?",
          answer:
            "You can fill out the contact form on our Customer Support page or email us at support@yourwebsite.com.",
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 2.8rem;
  color: #333;
  font-weight: bold;
}

#faq {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.accordion-button {
  font-weight: bold;
  background-color: #f8f9fa;
  color: #333;
}

.accordion-button.collapsed {
  background-color: #fff;
}

.accordion-body {
  font-size: 0.9rem;
}
</style>
