import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Import Bootstrap and BootstrapVueNext CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

// main.js
import "@fortawesome/fontawesome-free/css/all.css";

// Import Bootstrap's JS (Optional)
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Optional: Only if you need Bootstrap's JS components

// Import BootstrapVueNext
import BootstrapVueNext from "bootstrap-vue-next";

// Create the Vue app
const app = createApp(App);

app.use(BootstrapVueNext);
app.use(router);

app.mount("#app");
