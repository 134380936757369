<template>
  <div class="container mt-5">
    <div class="profile-card card shadow-lg p-4">
      <!-- Profile Picture Section -->
      <div class="text-center">
        <img :src="profilePicture" alt="Profile Picture" class="profile-img" />
        <h2 class="mt-3">{{ fullName }}</h2>
        <p class="text-muted">{{ email }}</p>
        <p class="bio-text">{{ bio }}</p>
      </div>

      <!-- Edit Profile Button -->
      <div class="text-center mt-4">
        <button class="btn btn-primary" @click="editProfile">
          Edit Profile
        </button>
      </div>

      <!-- Modal for Editing Profile -->
      <b-modal v-model="showEditModal" title="Edit Profile">
        <form @submit.prevent="updateProfile">
          <div class="mb-3">
            <label for="fullName" class="form-label">Full Name</label>
            <input
              type="text"
              id="fullName"
              class="form-control"
              v-model="form.fullName"
            />
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model="form.email"
            />
          </div>
          <div class="mb-3">
            <label for="bio" class="form-label">Bio</label>
            <textarea
              id="bio"
              class="form-control"
              v-model="form.bio"
              rows="3"
            ></textarea>
          </div>
          <div class="mb-3">
            <label for="profilePicture" class="form-label"
              >Profile Picture URL</label
            >
            <input
              type="text"
              id="profilePicture"
              class="form-control"
              v-model="form.profilePicture"
            />
          </div>
          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-success">Save Changes</button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfilePage",
  data() {
    return {
      fullName: "John Doe", // Example data, replace with dynamic data
      email: "johndoe@example.com", // Example email, replace with dynamic data
      bio: "Full Stack Developer, Enthusiast of clean code and great coffee.",
      profilePicture: "https://via.placeholder.com/150", // Placeholder profile picture, replace with dynamic
      showEditModal: false, // Modal visibility
      form: {
        fullName: "",
        email: "",
        bio: "",
        profilePicture: "",
      },
    };
  },
  methods: {
    editProfile() {
      this.form.fullName = this.fullName;
      this.form.email = this.email;
      this.form.bio = this.bio;
      this.form.profilePicture = this.profilePicture;
      this.showEditModal = true; // Show the modal
    },
    updateProfile() {
      // Update the profile information
      this.fullName = this.form.fullName;
      this.email = this.form.email;
      this.bio = this.form.bio;
      this.profilePicture = this.form.profilePicture;
      this.showEditModal = false; // Close the modal
    },
  },
};
</script>

<style scoped>
/* Container styling */
.container {
  max-width: 700px;
  margin: 0 auto;
}

/* Profile Card Styling */
.profile-card {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Profile Image Styling */
.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #007bff;
}

/* Bio Text */
.bio-text {
  font-style: italic;
  color: #6c757d;
}

/* Button Styling */
.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}
</style>
