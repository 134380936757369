<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-top">
        <img src="@/assets/logo-givio.png" alt="Logo" class="footer-logo" />
        <hr />
      </div>

      <div class="footer-buttons">
        <a href="mailto:info@givio.io" class="footer-btn">
          <i class="fas fa-envelope"></i> Email Us
        </a>
        <a
          href="https://discord.gg/jvtsstjBap"
          target="_blank"
          class="footer-btn"
        >
          <i class="fab fa-discord"></i> Discord
        </a>
        <a href="https://x.com/AdaGivio" target="_blank" class="footer-btn">
          <i class="fab fa-twitter"></i> Twitter
        </a>
        <a
          href="https://www.instagram.com/ada.givio/"
          target="_blank"
          class="footer-btn"
        >
          <i class="fab fa-instagram"></i> Instagram</a
        >
        <a href="#" target="_blank" class="footer-btn">
          <i class="fab fa-youtube"></i> Youtube
        </a>
        <router-link to="/about" class="footer-btn">
          <i class="fas fa-info-circle"></i> About Us
        </router-link>
        <a href="/customer-support" class="footer-btn">
          <i class="fas fa-address-book"></i> Contact
        </a>
        <a href="/privacy-policy" class="footer-btn">
          <i class="fas fa-user-shield"></i> Privacy Policy
        </a>
      </div>

      <hr />

      <!-- Divider -->
      <div class="footer-divider"></div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #000;
  color: white;
  padding: 2rem 1rem;
  text-align: center;
}

.footer-logo {
  width: 25%; /* Adjust percentage to fit your design */
  max-width: 400px; /* Optional: Set a max width to avoid the logo getting too large */
  margin-bottom: 1rem;
}

.footer-divider {
  width: 80%;
  height: 1px; /* Make the divider thinner */
  background-color: white;
  margin: 1rem auto;
}

.footer-buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  max-width: 80%;
  margin: 1rem auto;
}

.footer-btn {
  background-color: #555;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid white;
  justify-content: center;

  min-width: 150px; /* Set a minimum width */
  max-width: 200px; /* Set a maximum width */
  text-align: center;
  box-sizing: border-box;
  flex-grow: 1; /* Allow buttons to grow uniformly */
}

.footer-btn i {
  font-size: 1.25rem;
}

hr {
  border-top: 0px solid white; /* Make the hr divider thin */
  margin: 1rem 0;
}
</style>
