<template>
  <section id="customer-support" class="container mt-5">
    <h1 class="text-center mb-4">Customer Support</h1>

    <!-- Introductory Text -->
    <div class="row mb-5">
      <div class="col-md-8 offset-md-2 text-center">
        <p class="lead">
          We are here to help! If you have any questions, issues, or require
          assistance with any of our products or services, please don’t hesitate
          to reach out. Our dedicated support team is available to ensure your
          experience with us is smooth and trouble-free.
        </p>
        <p>
          Feel free to fill out the form below, and one of our support
          specialists will get back to you as soon as possible. Your
          satisfaction is our priority.
        </p>
      </div>
    </div>

    <!-- Contact Form -->
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h2 class="mb-3">Contact Us</h2>
        <form @submit.prevent="submitSupportRequest">
          <div class="mb-3">
            <label for="name" class="form-label">Your Name</label>
            <input
              type="text"
              id="name"
              class="form-control"
              v-model="supportRequest.name"
              required
            />
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">Email Address</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model="supportRequest.email"
              required
            />
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea
              id="message"
              rows="4"
              class="form-control"
              v-model="supportRequest.message"
              required
            ></textarea>
          </div>

          <button type="submit" class="btn btn-primary w-100">
            Send Message
          </button>
        </form>
      </div>
    </div>

    <!-- Additional Support Info -->
    <div class="row mt-5">
      <div class="col-md-8 offset-md-2 text-center">
        <h3 class="mb-4">Other Ways to Reach Us</h3>
        <p>
          If you prefer, you can also reach us through the following channels:
        </p>
        <ul class="list-unstyled">
          <li><i class="bi bi-envelope"></i> Email: info@givio.io</li>
          <li>
            <i class="bi bi-discord"></i>
            <a href="https://discord.gg/jvtsstjBap" target="_blank"
              >Contact us on Discord</a
            >
          </li>
          <li>
            <i class="bi bi-twitter"></i>
            <a href="https://x.com/AdaGivio" target="_blank"
              >Contact us on Twitter</a
            >
          </li>
          <li>
            <i class="bi bi-clock"></i> Business Hours: Monday to Friday, 9 AM -
            5 PM
          </li>
        </ul>
        <p class="mt-4">We aim to respond to all queries within 24 hours.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      supportRequest: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitSupportRequest() {
      alert(`Support request submitted by ${this.supportRequest.name}`);
      this.supportRequest = { name: "", email: "", message: "" };
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 2.8rem;
  color: #333;
  font-weight: bold;
}

#customer-support {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
}

textarea {
  resize: none;
}

ul.list-unstyled {
  list-style-type: none;
  padding: 0;
}

ul.list-unstyled li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.bi {
  font-size: 1.2rem;
  margin-right: 10px;
}

.container h3 {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}
</style>
