<template>
  <section class="container mt-5">
    <!-- Gift Cards List in a Card -->
    <div class="card card-styled">
      <h1 class="text-center mb-5">Gift Cards</h1>
      <!-- Search and Categories Section -->
      <section class="mb-5 search-filter-section">
        <div class="row mb-4">
          <div class="col-md-8">
            <div class="input-group search-box">
              <span class="input-group-text" id="search-icon">
                <i class="bi bi-search"></i>
              </span>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Search for gift cards..."
                v-model="searchQuery"
                @input="filterGiftCards"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group category-filter">
              <label class="input-group-text" for="category-select">
                <i class="bi bi-filter"></i>
              </label>
              <select
                class="form-select form-select-lg"
                v-model="selectedCategory"
                @change="filterGiftCards"
                id="category-select"
              >
                <option value="">All Categories</option>
                <option v-for="category in categories" :key="category">
                  {{ category }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Gift Cards Grid -->
        <div class="row">
          <div
            v-for="giftCard in filteredGiftCards"
            :key="giftCard.id"
            class="col-md-4 mb-4"
          >
            <div
              class="card card-products h-100 shadow-sm card-styled"
              @click="viewGiftCardDetails(giftCard)"
            >
              <img
                :src="giftCard.image"
                class="card-img-top"
                :alt="giftCard.brand"
              />
              <div class="card-body">
                <h5 class="card-title">{{ giftCard.brand }}</h5>
                <p class="card-text">{{ giftCard.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Gift Card Details Section -->
    <section
      v-if="selectedGiftCard"
      id="giftCardDetails"
      class="details-section mt-5"
    >
      <div class="card card-styled">
        <!-- Added card styling here -->
        <h2 class="text-center">{{ selectedGiftCard.brand }} Gift Card</h2>
        <div class="row mt-4">
          <!-- Left: Image Column -->
          <div class="col-md-5">
            <img
              :src="selectedGiftCard.image"
              class="details-img"
              :alt="selectedGiftCard.brand"
            />
            <div class="delivery-info mt-3">
              <p>
                <i class="fa fa-check-circle text-success"></i> Instant delivery
              </p>
              <p>
                <i class="fa fa-check-circle text-success"></i> Pay in your
                favourite currency
              </p>
            </div>
          </div>

          <!-- Right: Details Column -->
          <div class="col-md-7 d-flex flex-column align-items-center">
            <h4 class="mb-1 text-center">{{ selectedGiftCard.brand }}</h4>
            <p class="mb-1 small text-center">
              {{ selectedGiftCard.description }}
            </p>

            <h5 class="mb-1">Available Denominations:</h5>
            <ul class="small mb-1 text-center">
              <li v-for="value in selectedGiftCard.values" :key="value">
                {{ value }} C4
              </li>
            </ul>

            <!-- Quantity and Price Section -->
            <div
              class="quantity-price-section d-flex align-items-center mt-2 justify-content-center"
            >
              <div class="quantity-selector me-2 d-flex">
                <button
                  class="btn btn-outline-dark btn-sm"
                  @click="decreaseQuantity"
                >
                  -
                </button>
                <input
                  type="number"
                  v-model="quantity"
                  class="quantity-input mx-1"
                  :min="1"
                  :max="50"
                />
                <button
                  class="btn btn-outline-dark btn-sm"
                  @click="increaseQuantity"
                >
                  +
                </button>
              </div>
              <span class="price-display small ms-3">
                {{ selectedGiftCard.values[0] * quantity }} C4
              </span>
            </div>

            <!-- Estimated Price -->
            <div class="mt-2 small text-center">
              <p class="estimated-price mb-1">
                Estimated price in ADA:
                {{ calculateAdaPrice(selectedGiftCard.values[0] * quantity) }}
                ADA
              </p>
              <button class="btn btn-success btn-sm add-to-cart mt-1">
                Add to Cart
              </button>
            </div>

            <!-- Accordion for Additional Info -->
            <div class="accordion mt-3 w-100" id="giftCardDetailsAccordion">
              <div class="accordion-item">
                <h2 class="accordion-header" id="descriptionHeading">
                  <button
                    class="accordion-button btn-sm"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#descriptionCollapse"
                    aria-expanded="true"
                  >
                    Description
                  </button>
                </h2>
                <div
                  id="descriptionCollapse"
                  class="accordion-collapse collapse show"
                >
                  <div class="accordion-body small">
                    {{ selectedGiftCard.description }}
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="redeemingHeading">
                  <button
                    class="accordion-button btn-sm collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#redeemingCollapse"
                  >
                    Redeeming
                  </button>
                </h2>
                <div id="redeemingCollapse" class="accordion-collapse collapse">
                  <div class="accordion-body small">
                    Redeeming instructions go here...
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="termsHeading">
                  <button
                    class="accordion-button btn-sm collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#termsCollapse"
                  >
                    Terms & Conditions
                  </button>
                </h2>
                <div id="termsCollapse" class="accordion-collapse collapse">
                  <div class="accordion-body small">
                    Terms and conditions go here...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      selectedCategory: "",
      selectedGiftCard: null,
      quantity: 1,
      conversionRate: 250, // 1 ADA = 250 C4
      categories: ["Electronics", "Fashion", "Food & Drink", "Entertainment"],
      giftCards: [
        {
          id: 1,
          brand: "Amazon",
          description: "Shop millions of products at Amazon.",
          values: ["2500", "6250", "12500", "25000"], // C4 values
          image: require("@/assets/amazon.png"), // Local image
          category: "Electronics",
        },
        {
          id: 2,
          brand: "Apple",
          description: "Get the latest Apple products and accessories.",
          values: ["3750", "7500", "15000"], // C4 values
          image: require("@/assets/apple.png"), // Local image
          category: "Electronics",
        },
        {
          id: 3,
          brand: "Starbucks",
          description: "Enjoy your favorite coffee drinks.",
          values: ["1250", "2500", "6250"], // C4 values
          image: require("@/assets/starbucks.png"), // Local image
          category: "Food & Drink",
        },
        {
          id: 4,
          brand: "Bol.com",
          description: "Shop products at Bol.com.",
          values: ["2500", "6250", "12500"], // C4 values
          image: require("@/assets/bol.png"), // Local image
          category: "Electronics",
        },
        {
          id: 5,
          brand: "MediaMarkt",
          description: "Get your electronics at MediaMarkt.",
          values: ["6250", "12500", "25000"], // C4 values
          image: require("@/assets/mediamarkt.png"), // Local image
          category: "Electronics",
        },
      ],
    };
  },
  computed: {
    filteredGiftCards() {
      let filtered = this.giftCards;

      if (this.searchQuery) {
        filtered = filtered.filter((card) =>
          card.brand.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      if (this.selectedCategory) {
        filtered = filtered.filter(
          (card) => card.category === this.selectedCategory
        );
      }

      return filtered;
    },
  },
  methods: {
    filterGiftCards() {
      // This triggers computed property to update filteredGiftCards
    },
    viewGiftCardDetails(giftCard) {
      this.selectedGiftCard = giftCard;

      // Ensure the DOM has updated with the new selected gift card before scrolling
      this.$nextTick(() => {
        const detailsSection = document.querySelector("#giftCardDetails");
        if (detailsSection) {
          window.scrollTo({
            top: detailsSection.offsetTop,
            behavior: "smooth",
          });
        }
      });
    },
    increaseQuantity() {
      if (this.quantity < 50) {
        this.quantity += 1;
      }
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity -= 1;
      }
    },
    calculateAdaPrice(c4Amount) {
      return (c4Amount / this.conversionRate).toFixed(2); // Convert C4 to ADA
    },
  },
};
</script>

<style scoped>
#gift-card-section {
  padding: 40px;
}

h1 {
  font-size: 2.8rem;
  color: #333;
  font-weight: bold;
}

/* Card-styled reusable styles */
.card-styled {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  border: none; /* Ensure the border is removed */
}

.card-products {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1) !important;
}

/* Hover effect on the cards */
.card-products:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: transform 0.2s ease-in-out;
}

/* Card image styling */
.card-img-top {
  height: 180px;
  width: 100%;
  object-fit: contain;
  padding: 8px;
  background-color: #f8f9fa;
}

/* Stylish search and filter section */
.search-box {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.category-filter {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-box input {
  border: none;
  box-shadow: none;
  font-size: 1rem;
}

.search-box input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.7);
}

.category-filter select {
  border: none;
  box-shadow: none;
  font-size: 1rem;
}

.category-filter select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.7);
}

/* Gift card details image */
.details-img {
  height: 250px;
  width: 100%;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f8f9fa;
}

/* Quantity and Price */
.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-input {
  width: 50px;
  text-align: center;
  padding: 4px;
  font-size: 0.9rem;
}

.add-to-cart {
  background: radial-gradient(45deg, #747476, #1c1c1c) !important;
  color: #fff;
  border: solid 1.6px;
  text-decoration: none;
  border-radius: 30px; /* Rounded button */
  margin: 0 0.5rem;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  transition: background 0.5s ease-in-out, box-shadow 0.3s ease,
    transform 0.3s ease; /* Smooth hover effect */
}

.estimated-price {
  font-size: 1rem;
}

/* Accordion customization */
.accordion-item .accordion-header button {
  font-weight: bold;
  padding: 0.5rem;
}

.accordion-body {
  font-size: 0.9rem;
}

.delivery-info p {
  font-size: 0.9rem;
  margin: 4px 0;
}

/* Card styling for gift card details */
#giftCardDetails h2 {
  margin-bottom: 20px;
  font-weight: bold;
}

#giftCardDetails .price-display {
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
