<template>
  <div class="login-page">
    <div class="login-container shadow-lg rounded">
      <h2 class="text-center mb-4">Login to Your Account</h2>

      <form @submit.prevent="handleLogin">
        <div class="form-floating mb-3">
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="email"
            placeholder="name@example.com"
            required
          />
          <label for="email">Email address</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            placeholder="Password"
            required
          />
          <label for="password">Password</label>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <input type="checkbox" id="remember" v-model="remember" />
            <label for="remember" class="ms-1">Remember me</label>
          </div>
          <a href="#" class="forgot-password">Forgot Password?</a>
        </div>

        <button type="submit" class="btn btn-primary w-100">Login</button>
      </form>

      <div class="text-center mt-3">
        <p class="mb-0">Don’t have an account?</p>
        <router-link to="/sign-up" class="text-primary"
          >Sign up here</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      remember: false,
    };
  },
  methods: {
    handleLogin() {
      console.log("Email:", this.email);
      console.log("Password:", this.password);
      console.log("Remember me:", this.remember);
    },
  },
};
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 20%);
  position: relative;
  background-image: url("@/assets/givio-brand-logo.png"); /* Logo as background */
  background-repeat: repeat; /* Repeat the logo */
  background-size: 200px 200px; /* Adjust the size of the logos */
  background-position: center; /* Center the background */
  background-attachment: fixed; /* Keep background fixed */
}

.login-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1; /* Ensure the login box is on top */
}

.form-floating input {
  border-radius: 8px;
}

.forgot-password {
  color: #007bff;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

button {
  background-color: #007bff;
  border: none;
}

button:hover {
  background-color: #0056b3;
}
</style>
