<template>
  <section id="features">
    <div class="features-content">
      <div class="feature">
        <i class="bi bi-shield-lock feature-icon"></i>
        <h2>Privacy</h2>
        <p>No KYC required – Your transactions are private.</p>
      </div>
      <div class="feature">
        <i class="bi bi-credit-card feature-icon"></i>
        <h2>Ease of Use</h2>
        <p>Buy gift cards effortlessly with Cardano tokens.</p>
      </div>
      <div class="feature">
        <i class="bi bi-bag-fill feature-icon"></i>
        <h2>Diverse Selection</h2>
        <p>Access gift cards from top brands worldwide.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturesComponent",
};
</script>

<style scoped>
/* General styling for the section */
#features {
  background: transparent; /* Fully transparent background */
  color: black;
  padding: 80px 20px;
  text-align: center;
}

/* Flexbox container for the features */
.features-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: transparent; /* Make content background transparent */
  border-radius: 15px;
  box-shadow: none; /* Remove the shadow from the content */
  transition: all 0.3s ease;
}

/* Individual feature card */
.feature {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

/* Icon styles */
.feature-icon {
  font-size: 60px;
  color: #007bff;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.feature:hover .feature-icon {
  color: #0056b3;
}

/* Heading and paragraph styles */
.feature h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
}

.feature p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.5;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .features-content {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .feature-icon {
    font-size: 48px;
  }

  .feature h2 {
    font-size: 1.5rem;
  }

  .feature p {
    font-size: 1rem;
  }
}
</style>
