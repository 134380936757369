<template>
  <section class="container mt-5">
    <!-- Outer card wrapping everything -->
    <div class="card card-styled">
      <!-- Pass Sale Heading -->
      <h1 class="text-center mb-5">Pass Sale</h1>

      <!-- Pass Details Section -->
      <section class="row pass-details mb-5">
        <div class="col-md-6 mb-4">
          <div
            class="card card-styled h-100 shadow-sm d-flex flex-row align-items-center"
          >
            <div class="card-body flex-grow-1">
              <h3 class="card-title">Diamond VIP Pass</h3>
              <p><strong>Price:</strong> 500,000 C4 tokens</p>
              <p><strong>Perks:</strong></p>
              <ul>
                <li>Highest fee distribution</li>
                <li>Exclusive voting power</li>
                <li>Priority support</li>
                <li>Early access to new features</li>
              </ul>
            </div>
            <img
              src="@/assets/diamond-vip-pass.png"
              alt="Diamond VIP Pass"
              class="pass-img align-self-center"
            />
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div
            class="card card-styled h-100 shadow-sm d-flex flex-row align-items-center"
          >
            <div class="card-body flex-grow-1">
              <h3 class="card-title">Gold Exclusive Pass</h3>
              <p><strong>Price:</strong> 350,000 C4 tokens</p>
              <p><strong>Perks:</strong></p>
              <ul>
                <li>Standard fee distribution</li>
                <li>Special offers</li>
                <li>Access to exclusive events</li>
                <li>Newsletter with insider tips</li>
              </ul>
            </div>
            <img
              src="@/assets/gold-exclusive-pass.png"
              alt="Gold Exclusive Pass"
              class="pass-img align-self-center"
            />
          </div>
        </div>
      </section>

      <!-- Why Secure a Pass Section -->
      <section class="mb-5">
        <h2 class="text-center mb-4">Why Secure a Pass?</h2>
        <p>
          By securing a pass, you'll be supporting the growth of a promising
          platform. The funds from the pass sales will be reinvested directly
          into platform development, marketing, and expanding our community.
          Plus, as a pass holder, you’ll receive a weekly report on sales and
          platform performance, keeping you in the loop on how the platform is
          evolving.
        </p>
        <p>
          But that’s not all, as a pass holder, you’re not just a client, you
          become a Partner in Givio. This means you’ll have a direct influence
          on the platform’s future and a stake in its success.
        </p>
        <p>
          And once the platform becomes profitable, our pass holders will be
          fully paid back, ensuring that your early support is rewarded.
        </p>
      </section>

      <!-- Purchase Process Section -->
      <section class="purchase-process mb-5">
        <h2 class="text-center mb-4">Purchase Process</h2>
        <ol class="list-group">
          <li class="list-group-item">
            <i class="bi bi-wallet2 me-2"></i>
            Payment Method: Send C4 tokens to the designated wallet address.
          </li>
          <li class="list-group-item">
            <i class="bi bi-envelope-check me-2"></i>
            Confirmation: After the payment is processed, you will receive an
            email confirmation.
          </li>
          <li class="list-group-item">
            <i class="bi bi-clock-history me-2"></i>
            Activation: Your pass will be activated within 24 hours.
          </li>
        </ol>
      </section>

      <!-- FAQ Section -->
      <section class="faq-section">
        <h2 class="text-center mb-4">FAQ</h2>
        <div class="accordion" id="faqAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
              >
                What is the limit on the number of passes I can purchase?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p>You can purchase up to 5 passes per account.</p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
              >
                Can I get a refund after purchasing a pass?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p>No, all sales are final and non-refundable.</p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
              >
                How do I contact support for issues?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p>
                  You can contact support via the "Contact Us" page or by
                  sending an email to support@example.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "PassSalePage",
};
</script>

<style scoped>
/* General card styling to match gift card design */
.card-styled {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: none;
}

/* List-group item styling */
.list-group-item {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

/* Purchase process icons */
.list-group-item i {
  color: #007bff;
  font-size: 1.2rem;
}

/* Accordion styling to match other card sections */
.accordion-button {
  background-color: #f8f9fa;
  font-weight: bold;
}

.accordion-body {
  font-size: 0.9rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.accordion-item {
  margin-bottom: 1rem;
  border: none;
}

/* Pass image styling to fit on the right side of the text */
.pass-img {
  height: 130px;
  width: 130px;
  object-fit: contain;
  border-radius: 10px;
  margin-left: 10px;
}
</style>
