<template>
  <NavBarComponent></NavBarComponent>
  <div id="app">
    <router-view />
  </div>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavBarComponent from "./components/NavBarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    FooterComponent,
    NavBarComponent,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");

/* Global styles can be added here */
body {
  margin: 0;
  font-family: "Merriweather", serif; /* Apply Merriweather font */
  color: #333;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 20%);
  position: relative;
  background-image: url("@/assets/givio-brand-logo.png"); /* Logo as background */
  background-repeat: repeat; /* Repeat the logo */
  background-size: 200px 200px; /* Adjust the size of the logos */
  background-position: center; /* Center the background */
  background-attachment: fixed; /* Keep background fixed */
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

router-view {
  flex: 1;
  display: flex;
}

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}
</style>
