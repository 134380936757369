<template>
  <section id="home">
    <div class="home-content">
      <h1>
        Buy Gift Cards with Cardano Native Tokens – Privacy and Convenience
        Redefined.
      </h1>
      <p>
        Purchase gift cards from major brands with Cardano tokens – No KYC
        required.
      </p>

      <!-- Slideshow Container -->
      <div class="slideshow-container">
        <div
          class="slideshow-track"
          :style="{
            transform: `translateX(-${currentSlide * (100 / visibleItems)}%)`,
          }"
        >
          <div
            v-for="(logo, index) in logos"
            :key="index"
            class="slideshow-slide"
          >
            <img :src="logo.src" :alt="logo.alt" class="slideshow-logo" />
          </div>
        </div>
      </div>

      <div class="cta-buttons">
        <router-link to="/gift-cards" class="cta-button"
          >Get Started</router-link
        >
        <router-link to="/pass-sale" class="cta-button">Buy Passes</router-link>
      </div>

      <br /><br />
      <hr />
    </div>
    <div class="features-guide-components">
      <FeaturesComponent />
      <br />
      <GuideComponent />
    </div>
  </section>
</template>

<script>
import FeaturesComponent from "@/components/FeaturesComponent.vue";
import GuideComponent from "@/components/GuideComponent.vue";

export default {
  name: "HomePage",
  components: {
    FeaturesComponent,
    GuideComponent,
  },
  data() {
    return {
      currentSlide: 0,
      logos: [
        { src: require("@/assets/amazon.png"), alt: "Amazon" },
        { src: require("@/assets/apple.png"), alt: "Apple" },
        { src: require("@/assets/bol.png"), alt: "Bol.com" },
        { src: require("@/assets/mediamarkt.png"), alt: "MediaMarkt" },
        { src: require("@/assets/starbucks.png"), alt: "Starbucks" },
      ],
      slideInterval: null, // For automatic slideshow
    };
  },
  computed: {
    visibleItems() {
      // Return 1 visible item on small screens, 2 items on larger screens
      return window.innerWidth <= 480 ? 1 : 2;
    },
  },
  mounted() {
    this.startSlideshow();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    this.stopSlideshow();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // Start the slideshow
    startSlideshow() {
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, 3000); // Change slides every 3 seconds
    },
    // Stop the slideshow (for cleanup)
    stopSlideshow() {
      clearInterval(this.slideInterval);
    },
    // Next slide function
    nextSlide() {
      const maxSlideIndex =
        Math.ceil(this.logos.length / this.visibleItems) - 1; // Prevent sliding into empty spaces
      if (this.currentSlide < maxSlideIndex) {
        this.currentSlide++;
      } else {
        this.currentSlide = 0; // Go back to first slide when reaching the end
      }
    },
    handleResize() {
      // Recompute the number of visible items on resize
      this.currentSlide = 0;
    },
  },
};
</script>

<style scoped>
#home {
  background: transparent; /* Make the background transparent */
  color: white;
  text-align: center;
}

#home h1 {
  font-size: 2.5rem;
  margin: 0;
}

#home p {
  font-size: 1.2rem;
}

.home-content {
  background-color: black;
  padding-top: 5rem;
}

/* Slideshow Container */
.slideshow-container {
  position: relative;
  width: 80%;
  height: 220px;
  margin: 2rem auto;
  overflow: hidden;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.31);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.slideshow-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

/* Each slide has spacing between them */
.slideshow-slide {
  width: calc(50% - 60px); /* Default for 2 items per slide */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px; /* This will create spacing between the logos */
}

.slideshow-logo {
  max-width: 250px;
  height: auto;
}

/* Call to Action Buttons */
.cta-buttons {
  margin-top: 2rem;
}

.cta-button {
  background: radial-gradient(45deg, #747476, #1c1c1c);
  color: #fff;
  padding: 1rem 2.5rem;
  border: solid 1.6px;
  text-decoration: none;
  border-radius: 30px; /* Rounded button */
  margin: 0 0.5rem;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  transition: background 0.5s ease-in-out, box-shadow 0.3s ease,
    transform 0.3s ease; /* Smooth hover effect */
}

.cta-button:hover {
  background: radial-gradient(45deg, #1c1c1c, #747476);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
  transform: translateY(-5px); /* Lift effect on hover */
  transition: background 0.5s ease-in-out, box-shadow 0.3s ease,
    transform 0.3s ease; /* Smooth hover effect */
}

.features-guide-components {
  background-color: transparent; /* Make this section transparent */
}

/* Responsive Styles */
@media (max-width: 768px) {
  #home {
    padding: 3rem 1rem;
  }

  #home h1 {
    font-size: 2rem;
  }

  #home p {
    font-size: 1rem;
  }

  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }

  .slideshow-logo {
    width: 120px; /* Increase logo size on medium devices */
  }
}

@media (max-width: 480px) {
  #home {
    padding: 2rem 0.5rem;
  }

  #home h1 {
    font-size: 1.5rem;
  }

  #home p {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
  }

  .slideshow-logo {
    width: 140px; /* Make logos larger on small devices */
  }

  .slideshow-slide {
    width: calc(100% - 40px); /* Adjust slide width to 1 logo per slide */
    margin: 0 20px;
  }
}
</style>
