<template>
  <div class="signup-page">
    <div class="signup-container shadow-lg rounded">
      <h2 class="text-center mb-4">Create an Account</h2>

      <form @submit.prevent="handleSignup">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="fullName"
            v-model="fullName"
            placeholder="Full Name"
            required
          />
          <label for="fullName">Full Name</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="email"
            placeholder="name@example.com"
            required
          />
          <label for="email">Email Address</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            placeholder="Password"
            required
          />
          <label for="password">Password</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            v-model="confirmPassword"
            placeholder="Confirm Password"
            required
          />
          <label for="confirmPassword">Confirm Password</label>
        </div>

        <!-- Country Dropdown Field -->
        <div class="form-floating mb-3">
          <select class="form-select" id="country" v-model="country" required>
            <option value="" disabled>Select Country</option>
            <option
              v-for="country in countries"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
          <label for="country">Country</label>
        </div>

        <!-- Crypto Wallet Address Input Field -->
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="walletAddress"
            v-model="walletAddress"
            placeholder="Crypto Wallet Address"
            required
          />
          <label for="walletAddress">Crypto Wallet Address</label>
        </div>

        <!-- Subtext with Info Icon -->
        <div class="info-text d-flex align-items-center mb-3">
          <i
            class="bi bi-info-circle text-primary me-2"
            title="It is crucial to enter the correct wallet address. Incorrect addresses will result in the inability to send gift cards/products."
          ></i>
          <small class="text-muted"
            >Ensure your wallet address is correct. We cannot verify or send
            products to incorrect addresses.</small
          >
        </div>

        <button type="submit" class="btn btn-primary w-100">Sign Up</button>
      </form>

      <div class="text-center mt-3">
        <p class="mb-0">Already have an account?</p>
        <router-link to="/login" class="text-primary">Log in here</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      walletAddress: "",
      country: "", // Added country field
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo (Congo-Brazzaville)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini (fmr. 'Swaziland')",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (North)",
        "Korea (South)",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar (formerly Burma)",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
    };
  },
  methods: {
    handleSignup() {
      // Handle signup logic here
      console.log("Full Name:", this.fullName);
      console.log("Email:", this.email);
      console.log("Password:", this.password);
      console.log("Confirm Password:", this.confirmPassword);
      console.log("Country:", this.country); // Log the selected country
      console.log("Crypto Wallet Address:", this.walletAddress); // Log wallet address
    },
  },
};
</script>

<style scoped>
.signup-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 20%);
  position: relative;
  background-image: url("@/assets/givio-brand-logo.png");
  background-repeat: repeat;
  background-size: 200px 200px;
  background-position: center;
  background-attachment: fixed;
}

.signup-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.form-floating input {
  border-radius: 8px;
}

button {
  background-color: #007bff;
  border: none;
}

button:hover {
  background-color: #0056b3;
}

/* Style for the info text and icon */
.info-text {
  font-size: 0.85rem;
}

.info-text i {
  cursor: pointer;
  font-size: 1.1rem;
}
</style>
