<template>
  <div id="about-us-page">
    <!-- Company Overview Section -->
    <section id="company-overview" class="py-5">
      <div class="container text-center">
        <h2 class="mb-4">Company Overview</h2>
        <p class="lead">
          We are dedicated to redefining the way people interact with digital
          assets. Our mission is to provide innovative solutions that combine
          privacy, security, and simplicity.
        </p>
        <p>
          Our vision is to create a world where digital transactions are
          seamless and accessible to everyone. We value integrity, innovation,
          and excellence in everything we do.
        </p>
        <div class="row">
          <div class="col-md-6">
            <!-- Replace image with FontAwesome or Bootstrap icon -->
            <i class="fas fa-users fa-5x mb-3"></i>
            <p>Our Team</p>
          </div>
          <div class="col-md-6">
            <!-- Replace image with FontAwesome or Bootstrap icon -->
            <i class="fas fa-building fa-5x mb-3"></i>
            <p>Our Office</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Our Story Section -->
    <section id="our-story" class="py-5">
      <div class="container text-center">
        <h2 class="mb-4">Our Story</h2>
        <p class="lead">
          Our journey began with a vision to make digital transactions more
          secure and private. Founded by a passionate team of innovators, our
          platform addresses the challenges of digital asset management.
        </p>
        <p>
          The idea was born out of frustration with existing solutions that
          compromised user privacy. We set out to build a platform that not only
          meets the needs of our users but exceeds their expectations.
        </p>
        <i class="fas fa-history fa-5x mt-4"></i>
        <p class="mt-2">Our Story Timeline</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUsPage",
  data() {
    return {
      team: [
        {
          name: "Bofke",
          title: "CEO",
          bio: "Bofke has over 20 years of experience in the tech industry...",
          photo: "@/assets/john.jpg",
        },
        {
          name: "Emal",
          title: "CTO",
          bio: "Emal is a visionary in blockchain technology...",
          photo: "@/assets/jane.jpg",
        },
        // Add more team members as needed
      ],
    };
  },
};
</script>

<style scoped>
#company-overview {
  background-color: #f8f9fa;
}

#our-story {
  background-color: #e9ecef;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

/* Center icons and adjust size */
.fa-users,
.fa-building,
.fa-history {
  color: #007bff;
  margin-bottom: 15px;
}
</style>
